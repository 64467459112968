import { Locale } from '@core/Entities/Locale/Locale.entity';

import { LanguageDropdown } from '@core/LanguageDropdown/LanguageDropdown';
import { Typography } from '@gds/Typography/Typography';

import { NavItem, SubNavGroup } from '../../Entities/Nav.entity';
import { itemOnClick, trackingFunction, TrackedLink, trackEvent } from '../TrackedLink/TrackedLink';

import { NavMenu } from './NavMenu/NavMenu';

import styles from './NavMenuWrapper.module.css';

export const NavMenuWrapper = ({
  navItems,
  locale,
  strings,
  isNavOpen = false,
  origin = 'header',
  menuHeader,
}: {
  navItems: NavItem[];
  locale: Locale;
  strings: Record<string, string>;
  isNavOpen: boolean;
  origin?: 'header' | 'footer';
  menuHeader?: string;
}) => {
  return (
    <div
      className={`${styles.navMenuWrapper} ${isNavOpen ? styles.addTransition : ''} ${
        origin === 'footer' ? styles.footerEnabled : ''
      }`}
    >
      {!!menuHeader && (
        <Typography variant="h6" className={styles.menuHeader}>
          {menuHeader}
        </Typography>
      )}
      <nav className={styles.navMenu}>
        <ul className={styles.navList}>
          {navItems.map((navItem, index) => {
            const { label, subNavGroups, href, redirectUrl } = navItem;

            return (
              <li
                key={index}
                className={styles.navItem}
                onClick={() =>
                  itemOnClick({
                    fn: trackingFunction,
                    obj: { label },
                  })
                }
              >
                {navItem.subNavGroups && navItem.subNavGroups.length > 0 ? (
                  <NavMenu
                    label={label}
                    subNavGroups={subNavGroups as SubNavGroup[]}
                    redirectUrl={
                      Array.isArray(redirectUrl) && redirectUrl[0] ? redirectUrl[0] : undefined
                    }
                    origin={origin}
                  />
                ) : (
                  <TrackedLink
                    className={styles.navLink}
                    href={href ?? '/'}
                    trackingObj={{ label: label, href: href }}
                    origin={origin}
                  >
                    <Typography variant="button1">{navItem.label}</Typography>
                  </TrackedLink>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
      <LanguageDropdown
        locale={locale}
        strings={{
          englishLabel: strings.englishLabel,
          frenchLabel: strings.frenchLabel,
        }}
        className={styles.languageDropdown}
        placeholderOption={strings.selectCountry}
        isMenuPositionedAbove
        trackingFunc={obj =>
          trackEvent({
            fn: trackingFunction,
            obj: { ...obj },
          })
        }
      />
    </div>
  );
};
