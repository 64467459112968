import React from 'react';

import { useModal } from '@core/Components/Modal/UseModal';
import { Accordion } from '@gds/Accordion/Accordion';
import { ArrowRight } from '@gds/Icons/Paths/ArrowRight';
import { ChevronLeft } from '@gds/Icons/Paths/ChevronLeft';
import { ChevronRight } from '@gds/Icons/Paths/ChevronRight';
import { Typography } from '@gds/Typography/Typography';

import { NavMenuProps } from '../../../Entities/Nav.entity';
import { SubNavItem } from '../../SubNavItem/SubNavItem';
import { TrackedLink, itemOnClick, trackingFunction } from '../../TrackedLink/TrackedLink';

import styles from './NavMenu.module.css';

export const NavMenu: React.FC<NavMenuProps> = ({ label, subNavGroups, redirectUrl, origin }) => {
  const { openModal } = useModal();

  const handleExpandedChange = (heading: string) => {
    itemOnClick({
      fn: trackingFunction,
      obj: { label: heading },
      origin: origin,
    });
  };

  const content = (
    <>
      {!!redirectUrl?.href && (
        <TrackedLink
          href={redirectUrl.href}
          trackingObj={{ label: redirectUrl.label, href: redirectUrl.href }}
          className={styles.redirectUrl}
          isNavMenuList
        >
          <Typography variant="buttonLink1" Component="div">
            {redirectUrl.label}
          </Typography>
          <ArrowRight />
        </TrackedLink>
      )}
      <div className={styles.subNavList}>
        {subNavGroups?.map(subNavGroup => {
          const { heading, subnavgroupitems, showAllLink } = subNavGroup;
          const navItemId = `nav-item-${label.replace(/^[^a-z]+|[^\w:.-]+/gi, '')}`;

          return (
            <Accordion
              key={heading}
              title={heading}
              onExpandedChange={() => handleExpandedChange(heading)}
              closedAccordionClassName={styles.closedAccordion}
            >
              <SubNavItem
                subnavgroupitems={subnavgroupitems}
                navHeading={heading}
                navItemId={navItemId}
                showAllLink={showAllLink}
                isNavMenuList
                origin={origin}
              />
            </Accordion>
          );
        })}
      </div>
    </>
  );

  const handleClick = () => {
    openModal(content, {
      header: label,
      headerVariant: 'h6',
      headerClassName: styles.modalHeader,
      customCloseIcon: <ChevronLeft />,
      screenWrapperClassName: `${styles.modalHeight} ${
        origin === 'footer' ? styles.footerEnabled : ''
      }`,
      enableHeaderClose: true,
    });
  };

  return (
    <Typography variant="button1" className={styles.titleWithIcon} onClick={handleClick}>
      {label}
      <ChevronRight />
    </Typography>
  );
};
