import { getRoundedReviewRating } from '@core/Utils/Reviews/GetRoundedReviewRating';
import { Typography } from '@gds/Typography/Typography';

import { SubNavItemProps } from '../../Entities/Nav.entity';
import { TrackedLink } from '../TrackedLink/TrackedLink';

import { ImageSubNavItem } from './ImageSubNavItem/ImageSubNavItem';
import { StandardSubNavItem } from './StandardSubNavItem/StandardSubNavItem';

import styles from './SubNavItem.module.css';

export const SubNavItem: React.FC<SubNavItemProps> = ({
  subnavgroupitems,
  navHeading,
  navItemId,
  showAllLink,
  isNavMenuList,
  origin,
}) => {
  const { href: showAllHref, label: showAllLabel } = showAllLink || {};
  const canShowMoreLink = !!showAllHref;

  return (
    <>
      <ul aria-label={`${navHeading} menu`} role="menu" className={styles.subNavListWrapper}>
        {subnavgroupitems.map(
          ({
            label,
            image,
            imageAltTexts,
            link,
            description,
            date,
            heycarRating,
            href,
            desc,
            logo,
          }) => (
            <li
              key={`${navItemId}-${navHeading}-${label}`}
              className={`${image ? styles.imageList : styles.standardList}`}
            >
              {image ? (
                <ImageSubNavItem
                  label={label}
                  image={image}
                  origin={origin}
                  imageAltTexts={imageAltTexts}
                  link={link}
                  description={description}
                  date={date}
                  rating={heycarRating ? getRoundedReviewRating(heycarRating) : undefined}
                  isNavMenuList={isNavMenuList}
                />
              ) : (
                <StandardSubNavItem
                  label={label}
                  href={href}
                  desc={desc}
                  origin={origin}
                  logo={logo}
                  imageAltTexts={imageAltTexts}
                  isNavMenuList={isNavMenuList}
                />
              )}
            </li>
          ),
        )}
      </ul>
      {canShowMoreLink && (
        <TrackedLink
          href={showAllHref}
          origin={origin}
          trackingObj={{ label: showAllLabel, href: showAllHref }}
          className={`${styles.showAll} ${
            subnavgroupitems.some(item => item.image) ? styles.addExtraPadding : ''
          }`}
        >
          <Typography variant="buttonLink2">{showAllLabel}</Typography>
        </TrackedLink>
      )}
    </>
  );
};
