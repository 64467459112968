'use client';

import { useAtom } from 'jotai';

import { useModal } from '@core/Components/Modal/UseModal';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { Button } from '@gds/Button/Button';
import { Close } from '@gds/Icons/Paths/Close';
import { Hamburger } from '@gds/Icons/Paths/Hamburger';
import { Typography } from '@gds/Typography/Typography';

import { NavItem } from '../../../Entities/Nav.entity';
import { NavMenuWrapper } from '../../NavMenuWrapper/NavMenuWrapper';
import { itemOnClick, trackingFunction } from '../../TrackedLink/TrackedLink';

import styles from '../NavButtons.module.css';

import { navTrayOpenAtom } from '../../../Atoms/NavTrayOpenAtom';

export const BurgerMenuButton = ({
  navItems,
  locale,
  strings,
  origin = 'header',
  className,
  iconLabel,
}: {
  navItems: NavItem[];
  locale: Locale;
  strings: Record<string, string>;
  origin?: 'header' | 'footer';
  className?: string;
  iconLabel?: string;
}) => {
  const [isNavTrayOpen, setIsNavTrayOpen] = useAtom(navTrayOpenAtom);
  const { closeModal } = useModal();

  const toggleBurgerMenu = (isOpen: boolean) => {
    setIsNavTrayOpen(isOpen);
    closeModal();
  };

  return (
    <>
      <Button
        className={`${className ? className : styles.notHorizontalNav} ${
          className ? className : styles.item
        }`}
        onClick={() =>
          itemOnClick(
            {
              fn: trackingFunction,
              obj: {
                label: 'Burger Menu Toggle',
                action: isNavTrayOpen ? 'close' : 'open',
              },
              origin,
            },
            () => toggleBurgerMenu(!isNavTrayOpen),
          )
        }
      >
        {isNavTrayOpen ? <Close /> : <Hamburger />}
        {iconLabel && (
          <Typography variant="caption5" Component="span">
            {iconLabel}
          </Typography>
        )}
      </Button>
      <NavMenuWrapper
        navItems={navItems}
        locale={locale}
        strings={strings}
        menuHeader={iconLabel}
        isNavOpen={isNavTrayOpen}
        origin={origin}
      />
    </>
  );
};
